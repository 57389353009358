import { combineReducers } from 'redux';

import accountReducer from './accountReducer';
import assessmentReducer from './assessmentReducer';
import questionReducer from './questionReducer';
import roleReducer from './roleReducer';
import skillReducer from './skillReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  assessment: assessmentReducer,
  question: questionReducer,
  role: roleReducer,
  skill: skillReducer
});

export default rootReducer;
